import api, { API_VERSION } from "./_http";

export const getAllPubProjectors = pubId => {
  return api.get(API_VERSION + `/projectors/pub/${pubId}`);
};

export const registerNewProjectors = (
  data = {
    id: null,
    macAddress: null,
    pub: 0,
  }
) => {
  return api.post(API_VERSION + `/projectors`, data);
};

export const unregisterProjectors = id => {
  return api.delete(API_VERSION + `/projectors/unregister/${id}`);
};
