import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3F51B5",
    },
    background: {
      default: "#FAFAFA",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          textFillColor: "rgba(0,0,0,.8)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(0,0,0,.7)",
            fontWeight: "bold",
          },
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },

  // custom style
  inputLabel: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
  },
  disabledText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.38)",
  },
});

export const pxToRem = theme.typography.pxToRem;
export default theme;
