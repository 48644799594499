import { createContext, useContext, useEffect, useState } from "react";

import { snackbarContext } from "./snackbarContext";

export const gameUploadContext = createContext();

const GameUploadContextProvider = ({ children }) => {
  const [percentage, setPercentage] = useState();
  const { setSnackBarMessage } = useContext(snackbarContext);

  useEffect(() => {
    if (percentage) {
      setSnackBarMessage(`Upload in progress. Please wait! , Uploaded ${percentage}%`);
    }
  }, [percentage, setSnackBarMessage]);

  return <gameUploadContext.Provider value={{ percentage, setPercentage }}>{children}</gameUploadContext.Provider>;
};

export default GameUploadContextProvider;
