import { useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";

import { barSchema } from "../helpers/form-schema";
import { updateBar } from "../services/barServices";

const defaultValues = {
  address: "",
  email: "",
  location: "",
  name: "",
  phone: "",
  isDemo: false,
};

const useBarDetail = barDetails => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLocked, setIsLocked] = useState(true);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(barSchema),
    defaultValues,
  });

  useEffect(() => {
    if (barDetails) {
      Object.keys(barDetails).map(key => {
        setValue(key, barDetails[key] || defaultValues[key]);
      });
    }
  }, [barDetails, setValue]);

  const handleUpdateBar = handleSubmit(async values => {
    const { ok } = await updateBar(barDetails.id, values);
    if (ok) {
      enqueueSnackbar("Save Pub Successful", { variant: "success" });
      setIsLocked(true);
    } else {
      enqueueSnackbar("Save Pub Error", { variant: "error" });
    }
  });

  const handleUnlock = useCallback(() => {
    setIsLocked(false);
  }, []);

  return {
    defaultValues,
    setValue,
    control,
    handleUpdateBar,
    errors,
    isValid,
    isSubmitting,
    isLocked,
    setIsLocked,
    handleUnlock,
  };
};

export default useBarDetail;
