import React, { useCallback, useContext, useMemo } from "react";

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { pxToRem } from "../../../../../../components/AppTheme/theme";
import TwoLineIcon from "../../../../../../components/Icons/TwoLineIcon";
import { loadingDataContext } from "../../../../../../context/loadingDataContext";
import { arrayToObject } from "../../../../../../helpers/utils";
import DurationInput from "./DurationInput";
import PromotionSelect from "./PromotionSelect";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * @todo Fix overflow dnd warning
 */
const BarPromotionTable = ({
  fields,
  setFormValue,
  getFormValue,
  promotionsSelect,
  disabled,
  selectedPromotions,
  setSelectedPromotions,
  promotions,
}) => {
  const { isLoadingData } = useContext(loadingDataContext);

  const handleDragEnd = useCallback(
    (result, _provided) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(fields, result.source.index, result.destination.index);
      setFormValue("promotions", items);
    },
    [fields, setFormValue]
  );

  const promotionMapById = useMemo(() => {
    return arrayToObject(promotionsSelect, "id");
  }, [promotionsSelect]);

  const handleChangeSelectAll = useCallback(() => {
    if (selectedPromotions.length !== fields.length) {
      setSelectedPromotions(fields.map((_, i) => i));
    } else {
      setSelectedPromotions([]);
    }
  }, [fields, selectedPromotions, setSelectedPromotions]);

  const handleChangeSelect = useCallback(
    index => {
      if (selectedPromotions.includes(index)) {
        const remaining = selectedPromotions.filter(it => it !== index);
        setSelectedPromotions(remaining);
      } else {
        setSelectedPromotions([...selectedPromotions, index]);
      }
    },
    [selectedPromotions, setSelectedPromotions]
  );

  const checkAllIndeterminate = useMemo(() => {
    return selectedPromotions.length > 0 && selectedPromotions.length < fields.length;
  }, [fields, selectedPromotions]);

  const checkAll = useMemo(() => {
    return selectedPromotions.length === fields.length && fields.length > 0;
  }, [fields, selectedPromotions]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        mt: pxToRem(42),
        opacity: isLoadingData || disabled ? 0.5 : 1,
        pointerEvents: isLoadingData ? "none" : "initial",
        width: "100%",
        overflowX: "initial",
        minHeight: 400,
      }}
    >
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ display: disabled ? "none" : "table-cell" }} />

            <TableCell align="left" sx={{ display: disabled ? "none" : "table-cell" }}>
              <Checkbox
                color="primary"
                size="medium"
                indeterminate={checkAllIndeterminate}
                checked={checkAll}
                onChange={handleChangeSelectAll}
              />
            </TableCell>

            <TableCell align="left">Promotion</TableCell>

            <TableCell align="left">Type</TableCell>

            <TableCell align="left">Duration</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="promotion" direction="vertical">
            {droppableProvided => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {promotions.map((item, index) => {
                  return (
                    <Draggable key={item?.id} draggableId={item?.id?.toString() || index.toString()} index={index}>
                      {(draggableProvided, snapshot) => {
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none",
                            }}
                          >
                            <TableCell
                              align="left"
                              width={25}
                              size="small"
                              sx={{
                                display: disabled ? "none" : "table-cell",
                                opacity: selectedPromotions.length > 0 ? 0 : 1,
                                pointerEvents: selectedPromotions.length > 0 ? "none" : "initial",
                              }}
                            >
                              <div {...draggableProvided.dragHandleProps}>
                                <TwoLineIcon />
                              </div>
                            </TableCell>

                            <TableCell
                              align="left"
                              width={25}
                              size="small"
                              sx={{ display: disabled ? "none" : "table-cell" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={selectedPromotions.includes(index)}
                                onChange={() => handleChangeSelect(index)}
                              />
                            </TableCell>

                            <TableCell align="left" width={300} size="small">
                              <PromotionSelect
                                // this key props is useful to tell react that something special
                                key={item.id}
                                disabled={disabled}
                                index={index}
                                setFormValue={setFormValue}
                                item={item}
                                getFormValue={getFormValue}
                                promotionsSelect={promotionsSelect}
                              />
                            </TableCell>

                            <TableCell align="left" width={280} size="small">
                              {promotionMapById[item.promotion.id]?.type === "IMAGE" ? "Image" : "Video"}
                            </TableCell>

                            <TableCell align="left" width={250} size="small">
                              <DurationInput
                                field={item}
                                setFormValue={setFormValue}
                                index={index}
                                disabled={disabled}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      {fields.length === 0 && (
        <Typography textAlign="center" mt={20}>
          No promotion
        </Typography>
      )}
    </TableContainer>
  );
};

export default BarPromotionTable;
