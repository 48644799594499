import { createContext, useState } from "react";

export const loadingDataContext = createContext();

const LoadingDataContextProvider = ({ children }) => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  return (
    <loadingDataContext.Provider value={{ isLoadingData, setIsLoadingData }}>{children}</loadingDataContext.Provider>
  );
};

export default LoadingDataContextProvider;
