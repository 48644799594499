import api, { cancelTokenSource } from "./_http";

export const uploadFile = (file, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file", file);

  return api.post("api/v1/files", formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
    cancelToken: cancelTokenSource.token,
  });
};
