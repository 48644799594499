import { useAuthState, useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";

import firebase, { firebaseAuth } from "../config/firebaseConfig";

const useAuth = () => {
  const [user, isLoading, error] = useAuthState(firebaseAuth);
  const [doSignInWithEmailAndPassword, __, isSigningIn, signInError] = useSignInWithEmailAndPassword(firebaseAuth);

  const signOut = () => firebaseAuth.signOut();

  const signInWithEmailAndPassword = async (email, password, enablePersistence = false) => {
    if (enablePersistence) await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await doSignInWithEmailAndPassword(email, password);
  };

  const resetPassword = async email => {
    const res = await firebaseAuth.sendPasswordResetEmail(email);
    return res;
  };
  return { user, isLoading, error, signInWithEmailAndPassword, signOut, isSigningIn, signInError, resetPassword };
};

export default useAuth;
