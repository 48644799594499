import * as yup from "yup";

import { phoneRegExp } from "./utils";

export const barSchema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required().matches(phoneRegExp, "Phone number is not valid"),
  location: yup.string().required(),
  isDemo: yup.boolean().required(),
});
