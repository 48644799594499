import constants from "../../../../helpers/constants";

const items = [
  {
    href: "/app/" + constants.ROUTE.DASHBOARD,
    title: "Dashboard",
  },
  {
    href: "/app/" + constants.ROUTE.GAMES.base,
    title: "Games",
    subItems: [
      {
        href: "/app/" + constants.ROUTE.GAMES.base + "/" + constants.ROUTE.GAMES.create,
        title: "Create Games",
      },

      {
        href: "/app/" + constants.ROUTE.GAMES.base + "/" + constants.ROUTE.GAMES.active,
        title: "Active Games",
      },
      {
        href: "/app/" + constants.ROUTE.GAMES.base + "/" + constants.ROUTE.GAMES.finished,
        title: "Finished Games",
      },
    ],
  },
  {
    href: "/app/" + constants.ROUTE.BAR,
    title: "Bars",
  },
  {
    href: "/app/" + constants.ROUTE.PROMOTIONS,
    title: "Promotions",
  },
];

export default items;
