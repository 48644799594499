import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

/**
 * @todo style this page
 */
const NotFound404 = () => (
  <>
    <Helmet>
      <title>404 | Material Kit</title>
    </Helmet>

    <Container maxWidth="md">
      <Typography align="center" color="textPrimary" variant="h1">
        404: The page you are looking for isn’t here
      </Typography>
    </Container>
  </>
);

export default NotFound404;
