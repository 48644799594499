import { lazy } from "react";

import { Navigate, Outlet } from "react-router-dom";

import DashBoardLayout from "./components/Layouts/DashboardLayout";
import MainBoardLayout from "./components/Layouts/MainLayout";
import constants from "./helpers/constants";
import BarDetail from "./pages/app/Bar/CreateBar/BarDetail";
import NotFound404 from "./pages/NotFound404";

const PromotionsPage = lazy(() => import("./pages/app/Promotions"));
const CreateGamePage = lazy(() => import("./pages/app/Games/CreateGame"));
const GameDetailPage = lazy(() => import("./pages/app/components/GameDetail"));
const FinishGamePage = lazy(() => import("./pages/app/Games/FinishedGame"));
const ActiveGamePage = lazy(() => import("./pages/app/Games/ActiveGame"));
const DashBoardPage = lazy(() => import("./pages/app/Dashboard"));
const CreateBar = lazy(() => import("./pages/app/Bar/CreateBar"));

const LoginPage = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

const routes = user => {
  return [
    {
      path: "app",
      element: user ? <DashBoardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: constants.ROUTE.DASHBOARD,
          element: <DashBoardPage />,
        },

        // game
        {
          path: constants.ROUTE.GAMES.base,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Navigate to={constants.ROUTE.GAMES.create} />,
            },
            {
              path: constants.ROUTE.GAMES.create,
              element: <CreateGamePage />,
            },
            {
              path: constants.ROUTE.GAMES.active,
              element: <ActiveGamePage />,
            },
            {
              path: `${constants.ROUTE.GAMES.active}/:id`,
              element: <GameDetailPage />,
            },
            {
              path: constants.ROUTE.GAMES.finished,
              element: <FinishGamePage />,
            },
            {
              path: `${constants.ROUTE.GAMES.finished}/:id`,
              element: <GameDetailPage />,
            },
          ],
        },
        // bar

        {
          path: constants.ROUTE.BAR,
          element: <CreateBar />,
        },
        {
          path: `${constants.ROUTE.BAR}/:id`,
          element: <BarDetail />,
        },

        // promo
        {
          path: constants.ROUTE.PROMOTIONS,
          element: <PromotionsPage />,
        },

        // fallback
        {
          path: "",
          element: <Navigate to={constants.ROUTE.DASHBOARD} />,
        },
        {
          path: "*",
          element: <NotFound404 />,
        },
      ],
    },
    {
      path: "",
      element: user ? <Navigate to="app" /> : <MainBoardLayout />,
      children: [
        {
          path: constants.ROUTE.LOGIN,
          element: <LoginPage />,
        },
        {
          path: constants.ROUTE.FORGOT_PASSWORD,
          element: <ResetPassword />,
        },
        {
          path: "",
          element: <Navigate to={constants.ROUTE.LOGIN} />,
        },
        {
          path: "*",
          element: <Navigate to={constants.ROUTE.LOGIN} />,
        },
      ],
    },
  ];
};

export default routes;
