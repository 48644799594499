import { useContext } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";

import { loadingDataContext } from "../../../../../../context/loadingDataContext";
import useBarDetail from "../../../../../../hooks/useBarDetail";
import BarForm from "../../BarForm";

const BarDetails = ({ pub }) => {
  const { isSubmitting, errors, control, handleUpdateBar, handleUnlock, isLocked } = useBarDetail(pub);
  const { isLoadingData } = useContext(loadingDataContext);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" color="primary.main">
            Bar Details
          </Typography>
        </Grid>

        <Grid item>
          {isLocked && (
            <Button
              sx={{ px: "45px" }}
              variant="contained"
              disabled={isSubmitting || isLoadingData}
              onClick={handleUnlock}
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>

      <BarForm errors={errors} control={control} isLocked={isLocked} />

      {!isLocked && (
        <Box textAlign="right">
          <Button
            sx={{ px: "45px", textAlign: "right" }}
            variant="contained"
            hidden={true}
            disabled={isLoadingData}
            onClick={handleUpdateBar}
          >
            Save
          </Button>
        </Box>
      )}
    </>
  );
};

export default BarDetails;
