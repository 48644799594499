import { Suspense, useState } from "react";

import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import LoadingIndicator from "../../LoadingIndicator";
import DashboardNavbar from "./components/DashboardNavbar";
import DashboardSidebar from "./components/DashboardSidebar";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 290,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

/**
 * @todo add loading indicator
 */
const DashBoardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar openMobile={isMobileNavOpen} onMobileClose={() => setMobileNavOpen(false)} />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Suspense
              fallback={
                <LoadingIndicator
                  containerProps={{
                    sx: {
                      display: "flex",
                      width: "100%",
                      height: "100vh",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                  loaderProps={{
                    size: 40,
                  }}
                />
              }
            >
              <Outlet />
            </Suspense>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashBoardLayout;
