import GameUploadContextProvider from "./gameUploadContext";
import LoadingDataContextProvider from "./loadingDataContext";
import SnackbarContextProvider from "./snackbarContext";
import SnackbarProvider from "./SnackbarProvider";

const GlobalContext = ({ children }) => {
  return (
    <SnackbarProvider>
      <LoadingDataContextProvider>
        <SnackbarContextProvider>
          <GameUploadContextProvider>{children}</GameUploadContextProvider>
        </SnackbarContextProvider>
      </LoadingDataContextProvider>
    </SnackbarProvider>
  );
};

export default GlobalContext;
