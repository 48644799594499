import { useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";

import { loadingDataContext } from "../context/loadingDataContext";
import { getPromotionsForSelect, getPubPromotions, savePubPromotions } from "../services/promotionService";

const defaultValues = {
  promotions: [],
  pub: {},
};

const useBarPromotion = barId => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    watch,
  } = useForm({
    defaultValues,
  });
  const [isLocked, setIsLocked] = useState(true);
  const [promotionsSelect, setPromotionsSelect] = useState([]);
  const [pubPromotions, setPubPromotions] = useState([]);
  const { setIsLoadingData } = useContext(loadingDataContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (pubPromotions) {
      setValue("promotions", pubPromotions.promotions || defaultValues.promotions);
      setValue("pub", pubPromotions.pub || defaultValues.pub);
    }
  }, [pubPromotions, setValue]);

  useEffect(() => {
    setIsLoadingData(true);

    (async () => {
      const { ok, data } = await getPubPromotions(barId);
      if (ok) {
        setPubPromotions(data);
      }
    })();

    (async () => {
      const { ok, data } = await getPromotionsForSelect();
      if (ok) {
        setPromotionsSelect(data);
      }
    })();

    setIsLoadingData(false);
  }, [barId, setIsLoadingData]);

  const handleUpdatePubPromotion = handleSubmit(async values => {
    const promotions = values.promotions.map((p, index) => {
      return {
        promotionId: p.promotion.id,
        duration: p.promotion.duration,
        priority: index + 1,
      };
    });

    const { ok } = await savePubPromotions(barId, { promotions });
    if (ok) {
      enqueueSnackbar("Save Promotion Successful", { variant: "success" });
    } else {
      enqueueSnackbar("Save Promotion Error", { variant: "error" });
    }

    setIsLocked(true);
  });

  return {
    setValue,
    control,
    handleUpdatePubPromotion,
    isSubmitting,
    getValues,
    promotions: watch("promotions"),
    isLocked,
    setIsLocked,
    promotionsSelect,
  };
};

export default useBarPromotion;
