import { Button, ListItem } from "@mui/material";
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";

const NavItem = ({ href, title, ...others }) => {
  const location = useLocation();

  // TODO: find a better solution for this
  const isChildActive = matchPath({ path: href }, location.pathname);
  const isParentActive = location.pathname.includes(href);
  const active = Boolean(isChildActive || isParentActive);

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
      {...others}
    >
      <Button
        component={RouterLink}
        sx={{
          color: "#000000DE",
          fontWeight: 400,
          fontSize: 16,
          justifyContent: "flex-start",
          py: 1.25,
          pl: 5,
          textTransform: "none",
          width: "100%",
          ...(active && {
            fontWeight: 700,
            color: "primary.main",
          }),
        }}
        to={href}
      >
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
