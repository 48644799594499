import { Suspense } from "react";

import { Box, Container, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

import constants from "../../../helpers/constants";
import LoadingIndicator from "../../LoadingIndicator";

const MainBoardLayout = () => {
  return (
    <Container>
      <Box
        sx={{
          height: {
            xs: "10vh",
            xl: "15vh",
          },
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="h6" mt="auto">
          {constants.APP_TITLE}
        </Typography>
      </Box>
      <Suspense
        fallback={
          <LoadingIndicator
            containerProps={{
              sx: {
                display: "flex",
                width: "100%",
                height: "90vh",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            loaderProps={{
              size: 40,
            }}
          />
        }
      >
        <Outlet />
      </Suspense>
    </Container>
  );
};

export default MainBoardLayout;
