import { useCallback, useContext } from "react";

import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Box, Button, Hidden, IconButton, LinearProgress, Toolbar, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import { loadingDataContext } from "../../../../context/loadingDataContext";
import constants from "../../../../helpers/constants";
import useAuth from "../../../../hooks/useAuth";
import { confirmLogoutDialogStyle } from "../../../../style/commonStyle";

const DashboardNavbar = ({ onMobileNavOpen }) => {
  const { isLoadingData } = useContext(loadingDataContext);
  const { user, signOut } = useAuth();
  const confirm = useConfirm();

  const handleSignOut = useCallback(async () => {
    await confirm({
      ...confirmLogoutDialogStyle,
      title: "",
      content: "Are you sure you want to log out?",
    });
    await signOut();
  }, [confirm, signOut]);

  return (
    <AppBar elevation={1} color="common">
      <Toolbar>
        <Typography variant="h6" pl={2}>
          {constants.APP_TITLE}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Typography mr={3}>{user.email}</Typography>

          <Button variant="contained" color="error" onClick={handleSignOut}>
            Logout
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      {isLoadingData && <LinearProgress />}
    </AppBar>
  );
};

export default DashboardNavbar;
