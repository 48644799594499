import { useEffect } from "react";

import { Drawer, Hidden } from "@mui/material";
import { useLocation } from "react-router-dom";

import NavContent from "./NavContent";

/**
 * @todo fix useEffect deps
 */
const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 290,
            },
          }}
          onClose={onMobileClose}
        >
          <NavContent />
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          PaperProps={{
            sx: {
              width: 290,
              top: 64,
              height: "calc(100% - 64px)",
              borderRight: 0,
            },
          }}
        >
          <NavContent />
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
