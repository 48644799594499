import { useContext } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { loadingDataContext } from "../../../../../../context/loadingDataContext";
import useProjectors from "../../../../../../hooks/useProjectors";
import AddTvDialog from "./AddTvDialog";
import TvTable from "./TvTable";

const BarTv = ({ pubId }) => {
  const { rows, setOpenAddTvDialog, openAddTvDialog, control, errors, handleAddTv, handleDeleteTV, handleOpenDialog } =
    useProjectors(pubId);
  const { isLoadingData } = useContext(loadingDataContext);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" color="primary.main">
            Projectors
          </Typography>
        </Grid>

        <Grid item>
          <Button
            sx={{ px: "45px" }}
            variant="contained"
            hidden={true}
            disabled={isLoadingData}
            onClick={handleOpenDialog}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <TvTable rows={rows} onDelete={handleDeleteTV} />

      <AddTvDialog
        handleAddTv={handleAddTv}
        open={openAddTvDialog}
        control={control}
        errors={errors}
        onClose={() => setOpenAddTvDialog(false)}
      />
    </>
  );
};

export default BarTv;
