import api, { API_VERSION } from "./_http";
import { uploadFile } from "./fileService";

export const createPromotion = async (
  file,
  onUploadProgress,
  data = {
    isDisabled: false,
    name: null,
    content: "No Content",
    description: "No Description",
  }
) => {
  const { ok, data: fileUrl, problem } = await uploadFile(file, onUploadProgress);

  if (ok) {
    data.url = fileUrl;
    data.isDisabled = false;
  } else {
    // TODO: make UI for this error
    // eslint-disable-next-line no-console
    console.error(problem);
    // throw new Error("fail to upload file");
  }

  return api.post(API_VERSION + "/promotions", data);
};

export const updatePromotion = async (
  promotionId,
  urlOrFile,
  isFile,
  onUploadProgress,
  data = {
    isDisabled: false,
    name: null,
    content: "No Content",
    description: "No Description",
  }
) => {
  if (isFile) {
    const { ok, data: fileUrl, problem } = await uploadFile(urlOrFile, onUploadProgress);
    if (ok) {
      data.url = fileUrl;
      data.isDisabled = false;
    } else {
      // TODO: make UI for this error
      // eslint-disable-next-line no-console
      console.error(problem);
      // throw new Error("fail to upload file");
    }
  } else {
    data.url = urlOrFile;
  }

  return api.put(API_VERSION + `/promotions/${promotionId}`, data);
};

export const getPromotionUploads = (size, page) => {
  return api.get(API_VERSION + "/promotions", { size, page });
};

export const getPubPromotions = pubId => {
  return api.get(API_VERSION + `/promotions/pub/${pubId}`);
};

export const getPromotionsForSelect = () => {
  return api.get(API_VERSION + "/promotions/list");
};

export const savePubPromotions = (pubId, data) => {
  return api.post(API_VERSION + `/promotions/pub/${pubId}`, data);
};

export const deletePromotions = promotionId => {
  return api.delete(API_VERSION + `/promotions/${promotionId}`);
};
