import React from "react";

import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const Input = ({ errors, label, control, name, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          disabled={disabled}
          error={Boolean(errors?.[name])}
          helperText={errors?.[name]?.message}
          label={label}
          margin="normal"
          variant="standard"
          placeholder="Required"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

const BarForm = ({ control, errors, isLocked, ...others }) => {
  return (
    <Grid container columnSpacing={4} rowSpacing={1} mb={10} {...others}>
      <Grid item xs={6}>
        <Input control={control} errors={errors} label="Name" name="name" disabled={isLocked} />
      </Grid>

      <Grid item xs={6}>
        <Input control={control} errors={errors} label="Email" name="email" disabled={isLocked} />
      </Grid>

      <Grid item xs={6}>
        <Input control={control} errors={errors} label="Phone number" name="phone" disabled={isLocked} />
      </Grid>

      <Grid item xs={6}>
        <Input control={control} errors={errors} label="Address" name="address" disabled={isLocked} />
      </Grid>

      <Grid item xs={12}>
        <Input control={control} errors={errors} label="Location" name="location" disabled={isLocked} />
      </Grid>
      {process.env.REACT_APP_MULTI_ACTIVE_ENABLED_DEMO == "true" && (
        <Grid item xs={12}>
          <FormControlLabel
            label="Demo"
            disabled={isLocked}
            control={
              <Controller
                name="isDemo"
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      disabled={isLocked}
                      onChange={e => field.onChange(e.target.checked)}
                    />
                  );
                }}
              />
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BarForm;
