import { Box, CircularProgress } from "@mui/material";

const LoadingIndicator = ({ containerProps, loaderProps }) => {
  return (
    <Box {...containerProps}>
      <CircularProgress {...loaderProps} />
    </Box>
  );
};

export default LoadingIndicator;
