import { useCallback, useContext, useEffect } from "react";

import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";

import { pxToRem } from "../../../../../components/AppTheme/theme";
import { loadingDataContext } from "../../../../../context/loadingDataContext";
import constants from "../../../../../helpers/constants";
import { deleteBar } from "../../../../../services/barServices";
import { confirmDeleteDialogStyle } from "../../../../../style/commonStyle";
import BarDetails from "./components/BarDetails";
import BarPromotion from "./components/BarPromotion";
import BarTv from "./components/BarTv";

const BarDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const pub = location.state;

  useEffect(() => {
    if (!pub || pub?.id !== +id) {
      navigate("/app/bar/create");
    }
  }, [id, navigate, pub]);

  const { isLoadingData } = useContext(loadingDataContext);

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteBar = useCallback(async () => {
    const { ok } = await deleteBar(pub.id);
    if (ok) {
      navigate("/app/bar");
      enqueueSnackbar("Delete Pub Successful", { variant: "success" });
    } else {
      enqueueSnackbar("Delete Pub Error", { variant: "error" });
    }
  }, [enqueueSnackbar, navigate, pub]);

  const handleShowConfirm = useCallback(async () => {
    await confirm({
      ...confirmDeleteDialogStyle,
      title: "Delete Bar",
      content: "This bar will automatically deleted when you click the Delete button.",
    });
    handleDeleteBar();
  }, [confirm, handleDeleteBar]);

  return (
    <>
      <Helmet>
        <title>{constants.APP_TITLE}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          pl: "2em !important",
          pr: "3em !important",
          marginTop: pxToRem(50),
          position: "relative",
          minHeight: "80vh",
          opacity: isLoadingData ? 0.5 : 1,
          pointerEvents: isLoadingData ? "none" : "initial",
        }}
      >
        <>
          <Box display="flex" mb={10}>
            <Typography variant="h4">PubBar</Typography>
            <Box flexGrow={1} />
            <Button
              sx={{ width: 150 }}
              variant="contained"
              color="error"
              disabled={isLoadingData}
              onClick={handleShowConfirm}
            >
              Delete
            </Button>
          </Box>

          <Grid container mt={3}>
            <Grid item component={Paper} xs={12} elevation={0} sx={{ p: "60px" }}>
              <BarDetails pub={pub} />
            </Grid>

            <Grid item xs={12} component={Paper} elevation={0} sx={{ p: "60px" }} mt={10} minHeight="500px">
              <BarPromotion pubId={pub?.id} />
            </Grid>

            <Grid item component={Paper} xs={12} elevation={0} sx={{ p: "60px" }} my={10} minHeight="500px">
              <BarTv pubId={pub.id} />
            </Grid>
          </Grid>
        </>
      </Container>
    </>
  );
};

export default BarDetail;
