import React, { useCallback, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import { getSecond, convertToMinuteSecondMaskInputFormat } from "../../../../../../helpers/utils";
import NumberCustomFormat from "../../../../components/GameDetail/component/NumberCustomFormat";

function DurationInput({ setFormValue, index, field, disabled }) {
  const [values, setValues] = useState({
    numberFormat: "0030",
  });

  const handleChange = useCallback(event => {
    setValues({
      [event.target.name]: event.target.value,
    });
  }, []);

  useEffect(() => {
    if (field.duration) {
      const duration = convertToMinuteSecondMaskInputFormat(field.duration);
      setValues({ numberFormat: duration });
    }
  }, [field.duration]);

  useEffect(() => {
    if (values.numberFormat) {
      const duration = getSecond(values.numberFormat);
      setFormValue(`promotions[${index}].promotion.duration`, duration);
    }
  }, [index, setFormValue, values]);

  const minuteSecondValidator = useCallback(({ formattedValue }) => {
    const durations = formattedValue.split(":");
    const second = durations[1]?.slice(0, -1);
    if (!second?.trim()) {
      return true;
    } else if (+second[0] > 5) {
      return false;
    }
    return true;
  }, []);

  return (
    <TextField
      fullWidth
      placeholder="duration"
      InputLabelProps={{ shrink: true }}
      margin="normal"
      value={values.numberFormat}
      name="numberFormat"
      variant={disabled ? "standard" : "outlined"}
      disabled={disabled}
      InputProps={{
        inputComponent: NumberCustomFormat,
        disableUnderline: disabled || false,
        inputProps: {
          format: "##:##",
          isAllowed: minuteSecondValidator,
        },
      }}
      onChange={handleChange}
    />
  );
}

export default DurationInput;
