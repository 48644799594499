import { useCallback, useContext, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { loadingDataContext } from "../context/loadingDataContext";
import { getAllPubProjectors, registerNewProjectors, unregisterProjectors } from "../services/ProjectorService";

const schema = yup.object().shape({
  macAddress: yup.string().required("Mac Address is Required"),
  name: yup.string().required("Mac Address is Required"),
});

const useProjectors = pubId => {
  const { setIsLoadingData } = useContext(loadingDataContext);
  const [rows, setRows] = useState([]);
  const [openAddTvDialog, setOpenAddTvDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      macAddress: "",
      name: "",
    },
    resolver: yupResolver(schema),
  });

  const fetchData = useCallback(async () => {
    if (!pubId) return;
    setIsLoadingData(true);
    const { data, ok } = await getAllPubProjectors(pubId);
    if (ok) {
      setRows(data);
    }
    setIsLoadingData(false);
  }, [pubId, setIsLoadingData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddTv = handleSubmit(async value => {
    setOpenAddTvDialog(false);
    const { ok } = await registerNewProjectors({ macAddress: value.macAddress, name: value.name, pub: pubId });
    if (ok) {
      reset();
      fetchData();
      enqueueSnackbar("Add Projector Successful", { variant: "success" });
    } else {
      enqueueSnackbar("Add Projector Error", { variant: "error" });
    }
  });

  const handleOpenDialog = useCallback(() => {
    setOpenAddTvDialog(true);
  }, [setOpenAddTvDialog]);

  const handleDeleteTV = useCallback(
    async id => {
      const { ok } = await unregisterProjectors(id);
      if (ok) {
        fetchData();
        enqueueSnackbar("Delete Projector Successful", { variant: "success" });
      } else {
        enqueueSnackbar("Delete Projector Error", { variant: "error" });
      }
    },
    [enqueueSnackbar, fetchData]
  );

  return {
    rows,
    reFetch: fetchData,
    openAddTvDialog,
    setOpenAddTvDialog,
    control,
    handleAddTv,
    errors,
    handleDeleteTV,
    handleOpenDialog,
  };
};

export default useProjectors;
