const constants = {
  APP_TITLE: "LASTCALL PUBQUIZ",
  ROUTE: {
    DASHBOARD: "dashboard",
    GAMES: {
      base: "game",
      create: "create",
      draft: "draft",
      active: "active",
      finished: "finish",
    },
    BAR: "bar",
    PROMOTIONS: "promo",
    TV: "tv",

    FORGOT_PASSWORD: "forgot-pwd",
    LOGIN: "login",
  },
};

export default constants;

export const QUERY_PARAMS = {
  currentPage: "p",
  rowsPerPage: "s",
  query: "q",
};

export const DEFAULT_PAGE_SIZE = 5;
export const MAX_PROMOTION_MEDIA_FILE_SIZE = 50 * 1024 * 1024; // 50mb

export const GAME_STATUS = {
  published: "PUBLISHED",
  countingDown: "COUNTING_DOWN",
  inProgress: "IN_PROGRESS",
  leaderboard: "LEADERBOARD",
  draft: "DRAFT",
  ended: "ENDED",

  getIsStatusHasLeaderboard: function (status) {
    return [this.inProgress, this.leaderboard, this.ended].includes(status);
  },
};
