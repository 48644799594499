import { useCallback, useContext, useState } from "react";

import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { loadingDataContext } from "../../../../../../context/loadingDataContext";
import useBarPromotion from "../../../../../../hooks/useBarPromotion";
import BarPromotionTable from "./BarPromotionTable";

const BarPromotion = ({ pubId }) => {
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const {
    control,
    setValue,
    getValues,
    handleUpdatePubPromotion,
    promotions,
    setIsLocked,
    isLocked,
    promotionsSelect,
  } = useBarPromotion(pubId);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "promotions",
  });

  const handleRemovePromotion = useCallback(() => {
    remove(selectedPromotions);
    setSelectedPromotions([]);
  }, [remove, selectedPromotions]);

  const handleUnlock = useCallback(() => {
    setIsLocked(false);
  }, [setIsLocked]);

  const handleAddMore = useCallback(() => {
    append({
      promotion: { id: null, duration: 30 },
      id: Date.now(), // this is used for generate unique id for drag and drop
    });
  }, [append]);

  const { isLoadingData } = useContext(loadingDataContext);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" color="primary.main">
            Promotions
          </Typography>
        </Grid>

        <Grid item>
          {isLocked ? (
            <Button sx={{ px: "45px" }} variant="contained" disabled={isLoadingData} onClick={handleUnlock}>
              Edit
            </Button>
          ) : (
            <Button variant="outlined" startIcon={<Add />} disabled={isLoadingData} onClick={handleAddMore}>
              Promotion
            </Button>
          )}
        </Grid>
      </Grid>

      <BarPromotionTable
        setSelectedPromotions={setSelectedPromotions}
        selectedPromotions={selectedPromotions}
        promotionsSelect={promotionsSelect}
        fields={fields}
        setFormValue={setValue}
        getFormValue={getValues}
        disabled={isLocked || isLoadingData}
        promotions={promotions}
      />

      {!isLocked && (
        <Box textAlign="right" mt={4}>
          <Button
            sx={{ px: "45px", mr: "1rem" }}
            disabled={isLoadingData || selectedPromotions.length === 0}
            color="error"
            onClick={handleRemovePromotion}
          >
            Remove
          </Button>
          <Button sx={{ px: "45px" }} variant="contained" disabled={isLoadingData} onClick={handleUpdatePubPromotion}>
            Save
          </Button>
        </Box>
      )}
    </>
  );
};

export default BarPromotion;
