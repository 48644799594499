import apisauce from "apisauce";

import { firebaseAuth } from "../config/firebaseConfig";

const baseURL = process.env.REACT_APP_MULTI_ACTIVE_API_BASEURL;

const api = apisauce.create({
  baseURL,
});

api.addAsyncRequestTransform(request => async () => {
  const { headers } = request;
  headers["Api-Key"] = process.env.REACT_APP_MULTI_ACTIVE_API_KEY;

  const token = await firebaseAuth.currentUser?.getIdToken();
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
});

/**
 * @todo: show toast about status 403
 */
api.addResponseTransform(response => {
  const { status } = response;
  if (status === 403 || status === 401) {
    firebaseAuth.signOut().then(_r => {
      /** TODO: show toast **/
    });
  }
});

export const API_VERSION = "/api/admin/v1";
export const cancelTokenSource = apisauce.CancelToken.source();
export default api;
