import api from "./_http";

const API_VERSION = "/api/admin/v1";

export const getAllBar = () => {
  return api.get(API_VERSION + "/pubs/all");
};
export const getBarUploaded = ({ limit, page }) => {
  return api.get(API_VERSION + "/pubs", { page, size: limit });
};

export const createBar = data => {
  return api.post(API_VERSION + "/pubs", data);
};

export const deleteBar = barId => {
  return api.delete(API_VERSION + `/pubs/${barId}`);
};

export const updateBar = (barId, data) => {
  return api.put(API_VERSION + `/pubs/${barId}`, data);
};
