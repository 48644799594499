import { SnackbarProvider as StackSnackBar } from "notistack";

const SnackbarProvider = ({ children }) => {
  return (
    <StackSnackBar autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
      {children}
    </StackSnackBar>
  );
};

export default SnackbarProvider;
