import { useCallback, useEffect, useState } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import { MenuItem, TextField } from "@mui/material";

const PromotionSelect = ({ item, setFormValue, promotionsSelect, index, disabled }) => {
  const [selected, setSelected] = useState(item.promotion.id);
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    setFormValue(`promotions[${index}].promotion.id`, +selected);
  }, [index, selected, setFormValue]);

  const handleChange = useCallback(e => {
    setSelected(e.target.value);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenSelect(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenSelect(false);
  }, []);

  useEffect(() => {
    if (!item?.promotion?.id) {
      setFormValue(`promotions[${index}].promotion.id`, +promotionsSelect?.[0]?.id);
      handleOpen();
    }
  }, [item, handleOpen, setFormValue, index, promotionsSelect, selected]);

  return (
    <TextField
      fullWidth
      select
      SelectProps={{
        open: openSelect,
        onClose: handleClose,
        onOpen: handleOpen,
        sx: { fontSize: "0.875rem" },
        IconComponent: disabled ? null : ArrowDropDown,
      }}
      value={selected || promotionsSelect?.[0]?.id}
      variant="standard"
      InputProps={{ disableUnderline: true }}
      disabled={disabled}
      onChange={handleChange}
    >
      {promotionsSelect.map(option => (
        <MenuItem key={option?.id} value={option.id.toString()}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PromotionSelect;
