import { Box, Button, Divider, Hidden, List } from "@mui/material";

import useAuth from "../../../../hooks/useAuth";
import NavItem from "./NavItem";
import items from "./navitems";

const NavContent = () => {
  const { signOut } = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Divider />
      <Box sx={{ py: 8 }}>
        <List>
          {items.map(item => {
            return (
              <div key={item.title}>
                <NavItem key={item.title} href={item.href} title={item.title} />
                {item.subItems && (
                  <Box pl="1rem">
                    {item.subItems.map(subItem => (
                      <NavItem key={subItem.title} href={subItem.href} title={subItem.title} />
                    ))}
                  </Box>
                )}
              </div>
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 2,
          mb: 4,
        }}
      >
        <Hidden lgUp>
          <Button color="primary" component="a" variant="contained" onClick={signOut}>
            Logout
          </Button>
        </Hidden>
      </Box>
    </Box>
  );
};

export default NavContent;
