import { forwardRef } from "react";

import NumberFormat from "react-number-format";

const NumberCustomFormat = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      isNumericString
      value={typeof value !== "number" ? value : ""}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export default NumberCustomFormat;
