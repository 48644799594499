import React, { useContext, useEffect } from "react";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { pxToRem } from "../../../../../../components/AppTheme/theme";
import { loadingDataContext } from "../../../../../../context/loadingDataContext";

const TvTable = ({ rows, onDelete }) => {
  const { isLoadingData, setIsLoadingData } = useContext(loadingDataContext);
  useEffect(() => {
    setIsLoadingData(false);
  }, [setIsLoadingData]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ mt: pxToRem(42), opacity: isLoadingData ? 0.5 : 1, pointerEvents: isLoadingData ? "none" : "initial" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" width="25%">
              ID
            </TableCell>
            <TableCell align="left" width="25%">
              Name
            </TableCell>
            <TableCell align="left" width="25%">
              Mac Address
            </TableCell>

            {rows.length > 0 && <TableCell align="left" width="25%" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.name || "No Name"}</TableCell>
              <TableCell align="left">{row.macAddress}</TableCell>
              <TableCell align="left">
                <Button variant="contained" color="error" onClick={() => onDelete(row.id)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {rows.length === 0 && (
        <Typography textAlign="center" mt={18}>
          No Projector
        </Typography>
      )}
    </TableContainer>
  );
};

export default TvTable;
