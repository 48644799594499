import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { CssBaseline } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router";

import AppTheme from "./components/AppTheme";
import LoadingIndicator from "./components/LoadingIndicator";
import GlobalContext from "./context";
import useAuth from "./hooks/useAuth";
import routes from "./routes";

const App = () => {
  const { user, isLoading } = useAuth();
  const router = useRoutes(routes(user));

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalContext>
          <AppTheme>
            <ConfirmProvider>
              <CssBaseline />
              {isLoading ? (
                <LoadingIndicator
                  containerProps={{
                    sx: {
                      display: "flex",
                      width: "100vw",
                      height: "100vh",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                  loaderProps={{
                    size: 80,
                  }}
                />
              ) : (
                router
              )}
            </ConfirmProvider>
          </AppTheme>
        </GlobalContext>
      </LocalizationProvider>
    </HelmetProvider>
  );
};

export default App;
