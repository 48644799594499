import { createContext, useState, forwardRef } from "react";

import { Alert as MuiAlert, Snackbar } from "@mui/material";

export const SNACK_BAR_SEVERITY = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

export const snackbarContext = createContext();

const SnackbarContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(SNACK_BAR_SEVERITY.info);
  const [Icon, setIcon] = useState();

  const openSnackBar = (severity, icon) => {
    setSeverity(severity);
    icon && setIcon(icon);
    setIsOpen(true);
  };

  const closeSnackBar = () => setIsOpen(false);

  return (
    <snackbarContext.Provider value={{ closeSnackBar, openSnackBar, setSnackBarMessage: setMessage }}>
      {children}
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={isOpen}>
        <Alert sx={{ width: "100%" }} icon={Icon && <Icon />} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </snackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
