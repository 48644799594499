export const confirmDialogStyle = {
  dialogProps: {
    maxWidth: "xs",
    sx: {
      "& .MuiTypography-root": {
        color: "primary.main",
        padding: "20px 44px 10px",
      },
      "& .MuiDialogContent-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "16px",
        width: "400px",
        padding: "30px 44px 40px",
      },
    },
  },
  confirmationText: "Confirm",
  confirmationButtonProps: {
    variant: "contained",
    sx: {
      mr: 3,
      px: 4,
      mb: 2,
    },
  },
  cancellationButtonProps: {
    sx: {
      display: "none",
    },
  },
};

export const confirmDeleteDialogStyle = {
  ...confirmDialogStyle,
  confirmationButtonProps: {
    ...confirmDialogStyle.confirmationButtonProps,
    variant: "contained",
    color: "error",
  },
  dialogProps: {
    ...confirmDialogStyle.dialogProps,
    sx: {
      "& .MuiTypography-root": {
        color: "error.main",
        padding: "20px 44px 10px",
      },
      "& .MuiDialogContent-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "16px",
        width: "400px",
        padding: "30px 44px 40px",
      },
    },
  },
  confirmationText: "Delete",
};

export const confirmLogoutDialogStyle = {
  ...confirmDeleteDialogStyle,
  confirmationText: "Yes",
};
