import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import theme, { pxToRem } from "../../../../../../components/AppTheme/theme";

const AddTvDialog = ({ open, onClose, control, errors, handleAddTv }) => {
  return (
    <Dialog open={open} scroll="body" maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ px: pxToRem(80), pt: pxToRem(40), ...theme.typography.h5 }} color="primary.main">
        Add TV
      </DialogTitle>
      <DialogContent sx={{ px: pxToRem(80), pt: pxToRem(40) }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label="Name"
                  margin="normal"
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="macAddress"
              defaultValue="1000"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label="Mac Address"
                  margin="normal"
                  error={Boolean(errors?.macAddress)}
                  helperText={errors?.macAddress?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ textAlign: "right", py: 5, px: pxToRem(80), bgcolor: "grey.50" }}>
        <Button sx={{ px: "25px" }} variant="contained" disabled={Boolean(errors?.macAddress)} onClick={handleAddTv}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTvDialog;
