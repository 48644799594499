import moment from "moment";

export const arrayToObject = (array, key, value) => {
  return array.reduce((obj, item) => Object.assign(obj, { [item[key]]: value ? item[value] : item }), {});
};

export const getSecond = value => {
  const minute = +value.slice(0, 2);
  const second = +value.slice(2, 4);
  return minute * 60 + second;
};

export const expandQueryParams = queryParams => {
  return "?" + queryParams.toString();
};

export const transformGameDetails = values => {
  // TODO: remove this when backend ready
  if (!values.name) {
    values.name = "No Name";
  }
  values.id = +values.id;
  values.answerDurations = +values.answerDurations;
  values.countdownDurations = +values.countdownDurations;
  values.questionDurations = +values.questionDurations;
  values.gameLeaderboardDurations = getSecond(values.gameLeaderboardDurations);
  values.startDate = moment(values.startDate).second(0).milliseconds(0).toISOString();
  return JSON.stringify(values);
};

const RIGHT_ANSWER_CHOICE = {
  first: "first",
  second: "second",
  third: "third",
  forth: "forth",
};

export const findCorrectAnswerChoice = oldAnswers => {
  let result = RIGHT_ANSWER_CHOICE.first;
  for (let i = 0; i < oldAnswers.length; i++) {
    if (oldAnswers[i].isRight) {
      switch (i) {
        case 0:
          return (result = RIGHT_ANSWER_CHOICE.first);
        case 1:
          return (result = RIGHT_ANSWER_CHOICE.second);
        case 2:
          return (result = RIGHT_ANSWER_CHOICE.third);
        case 3:
          return (result = RIGHT_ANSWER_CHOICE.forth);
      }

      break;
    }
  }
  return result;
};

export const transformQuizUpdate = values => {
  delete values.rightAnswer;
  delete values.id;

  return JSON.stringify(values);
};

export const zeroPad = (num, places) => {
  return String(num).padStart(places, "0");
};

export const convertToMinuteSecondMaskInputFormat = seconds => {
  let minute = Math.floor(seconds / 60);
  let secondRemaining = Math.round(seconds % 60);

  minute = zeroPad(minute, 2);
  secondRemaining = zeroPad(secondRemaining, 2);

  return minute + secondRemaining;
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const convertMinuteSecond = rawDuration => {
  if (!rawDuration) return "";
  let minute = Math.floor(rawDuration / 60);
  let second = rawDuration % 60;
  minute = zeroPad(minute, 2);
  second = zeroPad(second, 2);

  return minute + second;
};

export const convertSecond = rawSecond => {
  if (!rawSecond) return "";
  return zeroPad(rawSecond, 2);
};
